<template>
  <div style="height: inherit">
    <!-- Overlay -->
    <b-overlay :show="loading">
      <!-- Searchbar -->
      <div class="ecommerce-searchbar mt-1">
        <b-row>
          <b-col cols="12">
            <b-input-group class="input-group-merge">
              <b-form-input v-model="q" placeholder="Masukkan Judul e-book" class="search-product" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <!-- Prodcuts -->
      <div class="text-center mt-5 mb-5" v-if="data[0] == null">
        <b-img :src="require('@/assets/images/elp/empty.png')" fluid width="200px" />
        <h5 class="text-primary">E-book tidak ditemukan!!</h5>
      </div>
      <section class="grid-view wishlist-items mt-2" v-else>
        <b-card v-for="(item, i) in currentPageItems" :key="i" class="ecommerce-card" no-body>
          <div class="item-img text-center">
            <b-link :to="{ name: 'ebook-detail', params: { slug: item.slug, status: 'dimiliki' } }" class="m-3">
              <b-img :alt="`${item.name}-${item.id}`" fluid class="card-img-top" :src="apiFile + item.photo" style="width: 100%; height: 50%" />
            </b-link>
          </div>

          <!-- item Details -->
          <b-card-body>
            <div class="item-wrapper">
              <div>
                <small>UTBK</small>
              </div>
            </div>
            <h6 class="item-name">
              <b-link class="text-body" :to="{ name: 'ebook-detail', params: { slug: item.slug, status: 'dimiliki' } }">
                {{ item.name }}
              </b-link>
            </h6>
          </b-card-body>
        </b-card>
      </section>

      <!-- Pagination -->
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination v-model="filters.page" :total-rows="totalData" :per-page="filters.perPage" first-number align="center" last-number prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay, BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BOverlay,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,

    // SFC
  },
  computed: {
    apiFile() {
      return "http://file.elp-dev.my.id/";
    },

    currentPageItems() {
      let lengthAll = this.data.length;
      this.nbPages = 0;
      for (let i = 0; i < lengthAll; i = i + this.filters.perPage) {
        this.paginated_items[this.nbPages] = this.data.slice(i, i + this.filters.perPage);
        this.nbPages++;
      }

      return this.paginated_items[this.filters.page - 1];
    },
  },
  data() {
    return {
      loading: false,
      filterOptions: null,
      sortBy: null,
      sortByOptions: null,
      totalData: null,
      data: null,
      q: null,
      filters: {
        page: 1,
        perPage: 8,
      },
      paginated_items: {},
      currentPageIndex: 0,
      nbPages: 0,
    };
  },
  watch: {
    q: {
      handler(value) {
        if (this.q == "") {
          this.q = null;
          this.getData();
        } else {
          this.getData();
        }
      },
      deep: true,
    },
  },
  methods: {
    getData() {
      this.loading = true;
      let params = {
        search: this.q,
      };
      this.$store
        .dispatch("e-book/ebookSaya", params)
        .then((res) => {
          this.loading = false;
          this.data = res.data.data;
          this.totalData = this.data.length;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
